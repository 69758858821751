import React from "react";
import tw from "twin.macro";
import { Link, graphql } from "gatsby";
import { GatsbySeo } from 'gatsby-plugin-next-seo';
import Layout from "../components/layout.jsx";

const BlogIndex = ({ data }) => {
  const { edges: posts } = data.allMdx;

  return (
    <Layout>
      <h1 tw="text-2xl leading-10 font-bold mt-6">Blog posts</h1>
      <GatsbySeo title="Richard blog home"/>
      <hr tw="border my-2" />
      {posts.map(({ node: post }) => (
        <article key={post.id}>
          <Link tw="text-blue-ocean" to={post.fields.slug}>
            <h2 tw="text-xl leading-9 font-bold mt-5">{post.frontmatter.title||'Untitled'}</h2>
          </Link>
          <p>{post.excerpt}</p>
        </article>
      ))}
    </Layout>
  );
};

export const pageQuery = graphql`
  query postIndex {
    allMdx(
      sort: {
        fields: [frontmatter___date]
        order: DESC
      },
      filter: {
        frontmatter: {tags: {nin: "draft"}}
      }
    ) {
      edges {
        node {
          id
          excerpt
          frontmatter {
            title
            date
          }
          fields {
            slug
          }
        }
      }
    }
  }
`;

export default BlogIndex;
